





























































import { Component, Vue } from "vue-property-decorator";
import { ICityUpdate } from "@/interfaces";
import { dispatchGetCities, dispatchUpdateCity } from "@/store/admin/actions";
import { readAdminOneCity } from "@/store/admin/getters";
import { apiUrl } from "@/env";
import { getDistanceFromLatLonInKm } from "@/utils";
import { readHasAdminAccess } from "@/store/main/getters";

@Component
export default class EditCity extends Vue {
  public valid: boolean = true;
  public name: string = "";
  public isActive: boolean = true;
  public coatOfArms?: File;
  public currentPlace?: any;
  public center = { lat: 45.508, lng: -73.587 };
  public markerPosition = { lat: 45.508, lng: -73.587 };
  private radius?: number;

  private isWhitelabel = false;
  private oneSignalAppId?: string;
  private oneSignalApiKey?: string;
  private oneSignalExpirationDate?: Date;

  public setPlace(place) {
    this.currentPlace = place;
    this.center.lat = place.geometry.location.lat();
    this.center.lng = place.geometry.location.lng();

    this.markerPosition = {
      lat: this.center.lat,
      lng: this.center.lng,
    };

    const lat1 = place.geometry.viewport.getNorthEast().lat();
    const lng1 = place.geometry.viewport.getNorthEast().lng();
    const lat2 = place.geometry.viewport.getSouthWest().lat();
    const lng2 = place.geometry.viewport.getSouthWest().lng();
    this.radius = getDistanceFromLatLonInKm(lat1, lng1, lat2, lng2);
  }

  public get imageRules() {
    return (value: any) => !value;
  }

  public selectedImage(file: File) {
    this.coatOfArms = file;
  }

  public async mounted() {
    await dispatchGetCities(
      this.$store,
      readHasAdminAccess(this.$store) ?? false
    );
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    this.radius = undefined;

    if (this.city) {
      this.name = this.city.name;
      this.isActive = this.city.is_active;
      this.oneSignalAppId = this.city.one_signal_app_id;
      this.oneSignalApiKey = this.city.one_signal_api_key;
      this.oneSignalExpirationDate =
        this.city.one_signal_api_key_expiration_date;
      this.isWhitelabel =
        this.oneSignalAppId != null &&
        this.oneSignalApiKey != null &&
        this.city.one_signal_api_key_expiration_date != null;
      this.center = {
        lat: this.city.latitude,
        lng: this.city.longitude,
      };
      this.markerPosition = {
        lat: this.city.latitude,
        lng: this.city.longitude,
      };
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const cityUpdate: ICityUpdate = {
        latitude: this.markerPosition.lat,
        longitude: this.markerPosition.lng,
      };
      if (this.name) cityUpdate.name = this.name;
      if (this.coatOfArms) cityUpdate.coat_of_arms = this.coatOfArms;
      cityUpdate.is_active = this.isActive;
      if (this.isWhitelabel) {
        cityUpdate.one_signal_app_id = this.oneSignalAppId;
        cityUpdate.one_signal_api_key = this.oneSignalApiKey;
        cityUpdate.one_signal_api_key_expiration_date =
          this.oneSignalExpirationDate;
      }

      await dispatchUpdateCity(this.$store, {
        id: this.city!.id,
        city: cityUpdate,
      });
      this.$router.push("/main/admin/cities");
    }
  }

  get coatOfArmsUrl() {
    return `${apiUrl}/api/v1${this.city?.image_filename}`;
  }

  get city() {
    return readAdminOneCity(this.$store)(+this.$router.currentRoute.params.id);
  }
}
